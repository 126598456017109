import { createAction, props } from '@ngrx/store';
import { SurveyStatistics } from '../../models/survey-statistics.model';

export const SELECT = '[SurveyStatistics] Select';
export const UPDATE = '[SurveyStatistics] Update';
export const DELETE = '[SurveyStatistics] Delete';

export const select = createAction(SELECT, props<{ surveyStatistics: SurveyStatistics }>());
export const update = createAction(UPDATE, props<{ surveyStatistics: SurveyStatistics }>());
export const deleteSurveyStatistics = createAction(DELETE);

export const SurveyStatisticsActions = {
  select,
  update,
  deleteSurveyStatistics
};
