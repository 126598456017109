import { Action, createReducer, on } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

import { Cohort } from '../../models/cohort.model';
import { CohortActions } from './cohort-actions';

export const initialState: Cohort = null;

const cohortReducer = createReducer(
  initialState,
  on(CohortActions.select, setCohort),
  on(CohortActions.deleteCohort, () => null)
);

function setCohort(_, { cohort }: { cohort: Cohort }): Cohort {
  return cloneDeep(cohort);
}

export function reducer(state: Cohort, action: Action): Cohort {
  return cohortReducer(state, action);
}
