import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthApiService } from '../api-services/auth.api-service';
import { UserApiService } from '../api-services/user.api-service';
import { AppStoreState } from '../interfaces/app-store-state.interface';
import { AuthModel } from '../models/auth.model';
import { User } from '../models/user.model';
import * as UserActions from '../store/user/user-actions';
import { ApiAlertError } from '../decorators/api-alert-error.decorator';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private authApiService: AuthApiService,
    private userApiService: UserApiService,
    private store: Store<AppStoreState>,
    private router: Router
  ) {}

  @ApiAlertError()
  login(email: string, password: string): Observable<unknown> {
    return this.authApiService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setTokens(auth);
        return result;
      }),

      switchMap(() => this.getUserByToken())
    );
  }

  register(
    name: string,
    email: string,
    password: string,
    locale: string,
    newsletters: boolean,
    cohorts: boolean,
    surveys: boolean,
    dataUsage: boolean,
    emailSharing: boolean
  ): Observable<unknown> {
    return this.authApiService
      .register(name, email, password, locale, newsletters, cohorts, surveys, dataUsage, emailSharing)
      .pipe(
        map((auth: AuthModel) => {
          const result = this.setTokens(auth);
          return result;
        }),
        switchMap(() => this.getUserByToken())
      );
  }

  logout() {
    this.authApiService.logout().subscribe();
    this.removeTokens();
    this.store.dispatch(UserActions.deleteUser());
    this.router.navigate(['/home']);
  }

  getUserByToken(): Observable<unknown> {
    const auth = this.getTokens();

    if (!auth) {
      this.store.dispatch(UserActions.select({ user: null }));
      return of(undefined);
    }

    return this.userApiService.getUser().pipe(
      tap((user: User) => this.store.dispatch(UserActions.select({ user }))),
      catchError(error => {
        this.store.dispatch(UserActions.select({ user: null }));
        this.removeTokens();
        return throwError(error);
      })
    );
  }

  getAccessToken(): string {
    return this.getTokens()?.accessToken;
  }

  getRefreshToken(): string {
    return this.getTokens()?.refreshToken;
  }

  refreshToken(refreshToken: string): Observable<AuthModel> {
    return this.authApiService.refreshToken(refreshToken).pipe(tap((auth: AuthModel) => this.setTokens(auth)));
  }

  private setTokens(auth: AuthModel): boolean {
    if (auth && auth.accessToken) {
      localStorage.setItem('access_token', auth.accessToken);
      localStorage.setItem('refresh_token', auth.refreshToken);
      return true;
    }
    return false;
  }

  private getTokens(): AuthModel | null {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (accessToken && refreshToken) {
      return new AuthModel(accessToken, refreshToken);
    }
    return null;
  }

  private removeTokens(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }
}
