import { Action, createReducer, on } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

import { SurveyEntry } from '../../models/survey-entry.model';
import { SurveyEntryActions } from './survey-entry-actions';

export const initialState: SurveyEntry = null;

const surveyEntryReducer = createReducer(
  initialState,
  on(SurveyEntryActions.select, setSurveyEntry),
  on(SurveyEntryActions.update, setSurveyEntry),
  on(SurveyEntryActions.deleteSurveyEntry, () => null)
);

function setSurveyEntry(_, { surveyEntry }: { surveyEntry: SurveyEntry }): SurveyEntry {
  return cloneDeep(surveyEntry);
}

function getById(state, { surveyEntryId }: { surveyEntryId: number }): SurveyEntry {
  return {
    ...state
  };
}

export function reducer(state: SurveyEntry, action: Action): SurveyEntry {
  return surveyEntryReducer(state, action);
}
