import { Action, createReducer, on } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

import { SurveyStatistics } from '../../models/survey-statistics.model';
import { SurveyStatisticsActions } from './survey-statistics-actions';

export const initialState: SurveyStatistics = null;

const surveyStatisticsReducer = createReducer(
  initialState,
  on(SurveyStatisticsActions.select, setSurveyStatistics),
  on(SurveyStatisticsActions.update, setSurveyStatistics),
  on(SurveyStatisticsActions.deleteSurveyStatistics, () => null)
);

function setSurveyStatistics(_, { surveyStatistics }: { surveyStatistics: SurveyStatistics }): SurveyStatistics {
  return cloneDeep(surveyStatistics);
}

export function reducer(state: SurveyStatistics, action: Action): SurveyStatistics {
  return surveyStatisticsReducer(state, action);
}
