import { createAction, props } from '@ngrx/store';
import { Cohort } from '../../models/cohort.model';

export const SELECT = '[Cohort] Select';
export const DELETE = '[Cohort] Delete';

export const select = createAction(SELECT, props<{ cohort: Cohort }>());
export const deleteCohort = createAction(DELETE);

export const CohortActions = {
  select,
  deleteCohort
};
